import { Component } from "react";
import {Fade, Slide } from "react-awesome-reveal";

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contactName : '',
            contactEmail : '',
            contactSubject : '',
            contactPesan : ''
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const {name, value} = event.target;
        this.setState({
            [name] : value
        })

    }
     handleSubmit(event) {
        event.preventDefault(); // Mencegah form dari submit default
        const { contactName, contactEmail, contactSubject, contactPesan } = this.state;
        const message = `Name: ${contactName}\nEmail: ${contactEmail}\nSubject: ${contactSubject}\nMessage: ${contactPesan}`;
        const whatsappUrl = `https://wa.me/6281548769365?text=${encodeURIComponent(message)}`;
        window.open(whatsappUrl, '_blank'); // Membuka URL WhatsApp di tab baru
    }
    render() {
        if (!this.props.data) return null;

        const name = this.props.data.name;
        const street = this.props.data.address.street;
        const city = this.props.data.address.city;
        const state = this.props.data.address.state;
        const zip = this.props.data.address.zip;
        const phone = this.props.data.phone;
        const message = this.props.data.contactmessage;

        return (
            <section id="contact">
                <Fade bottom duration={1000}>
                    <div className="row section-head">
                        <div className="two columns header-col">
                            <h1>
                                <span>Get In Touch.</span>
                            </h1>
                        </div>

                        <div className="ten columns">
                            <p className="lead">{message}</p>
                        </div>
                    </div>
                </Fade>

                <div className="row">
                    <Slide left duration={1000}>
                    <div className="eight columns">
                        <form action="" method="post" id="contactForm" onSubmit={this.handleSubmit} name="contactForm">
                            <fieldset>
                            <div>
                                <label htmlFor="contactName">
                                Name <span className="required">*</span>
                                </label>
                                <input
                                type="text"
                                defaultValue=""
                                value={this.state.contactName}
                                size="35"
                                id="contactName"
                                name="contactName"
                                onChange={this.handleChange}
                                />
                            </div>

                            <div>
                                <label htmlFor="contactEmail">
                                Email <span className="required">*</span>
                                </label>
                                <input
                                type="text"
                                defaultValue=""
                                size="35"
                                value={this.state.contactEmail}
                                id="contactEmail"
                                name="contactEmail"
                                onChange={this.handleChange}
                                />
                            </div>

                            <div>
                                <label htmlFor="contactSubject">Subject</label>
                                <input
                                type="text"
                                defaultValue=""
                                size="35"
                                value={this.state.contactSubject}
                                id="contactSubject"
                                name="contactSubject"
                                onChange={this.handleChange}
                                />
                            </div>

                            <div>
                                <label htmlFor="contactMessage">
                                Message <span className="required">*</span>
                                </label>
                                <textarea
                                cols="50"
                                rows="15"
                                id="contactMessage"
                                value={this.state.contactPesan}
                                name="contactPesan" onChange={this.handleChange}
                                ></textarea>
                            </div>

                            <div>
                                <button className="submit">Submit</button>
                                <span id="image-loader">
                                <img alt="" src="images/loader.gif" />
                                </span>
                            </div>
                            </fieldset>
                        </form>

                        <div id="message-warning"> Error boy</div>
                        <div id="message-success">
                            <i className="fa fa-check"></i>Your message was sent, thank you!
                            <br />
                        </div>
                        </div>
                    </Slide>

                    <Slide right duration={1000}>
                        <aside className="four columns footer-widgets">
                        <div className="widget widget_contact">
                            <h4>Address and Phone</h4>
                            <p className="address">
                            {name}
                            <br />
                            {street} <br />
                            {city}, {state} {zip}
                            <br />
                            <span>{phone}</span>
                            </p>
                        </div>

                       
                        </aside>
                    </Slide>
                </div>
            </section>
        );
    }
}

export default Contact;